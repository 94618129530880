<template>
  <v-container fluid>
    <p class="text-h5 text-left">메뉴 관리</p>
    <div style="text-align: left; border: 1px solid #aaaaaa; padding: 20px">
      <label style="display: inline-block; width: 100px; text-align: right;">메뉴명</label>
      <input v-model="param.menuName" type="text" style="width:150px; margin: 0 10px" class="blue_input">
      <button type="button" class="pp_s" @click="search">검색</button>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="10%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="10%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue">기술이전 메뉴</th>
        <th class="td_dark_blue">순서</th>
        <th class="td_dark_blue">화면명</th>
        <th class="td_dark_blue">표시여부</th>
        <th class="td_dark_blue"><v-btn small elevation="0" color="primary" @click="addItem">추가</v-btn></th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th colspan="5" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ index + 1 }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.menuName }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">
            <span class="mr-4">{{ item.order }}</span>
<!--            <v-btn small icon><v-icon small>mdi-chevron-up</v-icon></v-btn>-->
<!--            <v-btn small icon><v-icon small>mdi-chevron-down</v-icon></v-btn>-->
          </td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.screenName }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">
            {{ item.viewYn === 'Y' ? '표시' : '' }}
          </td>
          <td class="td_blue cen bo" @click="itemSelect(item)">
            <v-btn small elevation="0" color="#bfc2c5" @click="removeItem(item, index)">삭제</v-btn>
          </td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="12">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="20%">
        <col width="30%">
        <col width="20%">
        <col width="30%">
      </colgroup>
      <tbody>
        <tr>
          <th class="td_dark_blue"><label for="txtMenuName">기술이전 메뉴</label></th>
          <td class="td_blue cen bo">
            <input
              v-model="selectedItem.menuName"
              type="text"
              style="width:96%"
              class="blue_input"
              id="txtMenuName"
            />
          </td>
          <th class="td_dark_blue"><label for="txtOrder">순서</label></th>
          <td class="td_blue cen bo">
            <input
              v-model="selectedItem.order"
              type="text"
              style="width:96%"
              class="blue_input"
              id="txtOrder"
            />
          </td>
        </tr>
        <tr>
          <th class="td_dark_blue"><label for="txtScreenName">화면명</label></th>
          <td class="td_blue cen bo">
            <input
              v-model="selectedItem.screenName"
              type="text"
              style="width:96%"
              class="blue_input"
              id="txtScreenName"
            />
          </td>
          <th class="td_dark_blue"><label for="txtViewYn">표시여부</label></th>
          <td class="td_blue cen bo">
            <input
              v-model="selectedItem.viewYn"
              class="ml-4 mr-2"
              type="checkbox"
              id="txtViewYn"
              true-value="Y"
              false-value="N"
            />
          </td>
        </tr>
      </tbody>

    </table>
    <div class="text-right mt-2">
      <v-btn small class="mr-2" elevation="0" color="primary" @click="saveItem" :disabled="selectedIndex < 0">저장</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'MenuManage',
  data() {
    return {
      param: {
        menuName: null
      },
      loading: false,
      items: [],
      selectedItem: {},
      selectedIndex: -1
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      this.loading = true
      this.$http.get('/api/sys/menu', {params: this.param})
      .then(({ data }) => {
        this.selectedItem = {}
        this.selectedIndex = -1
        this.items = data
        this.loading = false
      })
    },
    itemSelect(item, index) {
      this.selectedItem = item
      this.selectedIndex = index
    },
    addItem() {
      const newItem = {order: this.items.length + 1, isNew: true}
      this.items.push(newItem)
      this.selectedItem = this.items[this.items.length - 1]
      this.selectedIndex = this.items.length - 1
    },
    saveItem() {
      this.$http.post('/api/sys/menu', this.selectedItem)
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.search()
        }
      })
    },
    removeItem(item, index) {
      if (item.isNew) {
        this.items.splice(index, 1)
      } else {
        if (confirm('선택한 항목을 삭제하겠습니까?')) {
          this.$http.post('/api/sys/menu/remove', item)
          .then(({data}) => {
            if (data === 'success') {
              alert('삭제되었습니다.')
              this.search()
            }
          });
        }
      }
    }
  }
}
</script>
